<template>
  <header class="header">
    <!-- 头部的第一行 -->
    <div class="top">
      <div class="container">
        <div class="loginList">
          <p>尚品汇欢迎您！</p>
          <!-- 已登录状态 -->
          <p v-if="$store.state.user.userInfo.name">
            <!-- <a href="###">登录</a> -->
            <a href="javascript:;">{{$store.state.user.userInfo.name}}</a>
            <a href="javascript:;" @click="loginOut" style="margin-left:10px">退出登录</a>
          </p>
          <!-- 未登录 -->
          <p v-else>
            <span>请</span>
            <!-- <a href="###">登录</a> -->
            <router-link to="/login">登录</router-link>
            <router-link to="/register" class="register">免费注册</router-link>
          </p>
        </div>
        <div class="typeList">
          <router-link to="/center">我的订单</router-link>
          <router-link to="/shopcart">我的购物车</router-link>
          <a href="###">我的尚品汇</a>
          <a href="###">尚品汇会员</a>
          <a href="###">企业采购</a>
          <a href="###">关注尚品汇</a>
          <a href="###">合作招商</a>
          <a href="###">商家后台</a>
        </div>
      </div>
    </div>
    <!--头部第二行 搜索区域-->
    <div class="bottom">
      <h1 class="logoArea">
        <router-link to="/" class="logo" title="尚品汇">
          <img src="./images/logo.png" alt="" />
        </router-link>
      </h1>
      <div class="searchArea">
        <form action="###" class="searchForm">
          <input
            type="text"
            id="autocomplete"
            class="input-error input-xxlarge"
            v-model="searchKeyWord"
          />
          <!-- 阻止表单的默认提交行为 -->
          <button
            class="sui-btn btn-xlarge btn-danger"
            type="submit"
            @click.prevent="toSearch"
          >
            搜索
          </button>
        </form>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      searchKeyWord: "",
    };
  },
  mounted(){
    this.$bus.$on("clearSearch",this.clearSearch);
  },
  destroyed(){
    //解绑
    this.$bus.$off("clearSearch");
  },
  methods: {
    /* 退出登录 */
    async loginOut(){
      try {
        await this.$store.dispatch("setLoginOut");
        this.$message.success("退出登录成功!");
        //回到主页
        this.$router.push("/");
      } catch (error) {
        this.$message.error("退出失败!"+error);
      }
    },
    /* 清空搜索框 */
    clearSearch(){
      this.searchKeyWord="";
    },
    /* 提交搜索 */
    toSearch() {
      let { searchKeyWord } = this;
      if (searchKeyWord.trim()) {
        //如果当前头部所在组件为search
        let opts={
           name: "search",
          params:{
            keyword:searchKeyWord
          },
          //如果当前对象有query参数就传入
          query:this.$route.query
        };
        if(this.$route.name=="search"){
          this.$router.replace(opts);
        }
        this.$router.push(opts);
        //清空输入内容
        this.searchKeyWord="";
      } else {
        this.$message.warning("请输入完整的内容!");
      }
    },
  },
};
</script>

<style lang="less" scoped>
//头部
.header {
  & > .top {
    background-color: #eaeaea;
    height: 30px;
    line-height: 30px;
    .container {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      .loginList {
        float: left;
        p {
          float: left;
          margin-right: 10px;
          .register {
            border-left: 1px solid #b3aeae;
            padding: 0 5px;
            margin-left: 5px;
          }
        }
      }
      .typeList {
        float: right;
        a {
          padding: 0 10px;
          & + a {
            border-left: 1px solid #b3aeae;
          }
        }
      }
    }
  }
  & > .bottom {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .logoArea {
      float: left;
      .logo {
        img {
          width: 175px;
          margin: 25px 45px;
        }
      }
    }
    .searchArea {
      float: right;
      margin-top: 35px;
      .searchForm {
        overflow: hidden;
        input {
          box-sizing: border-box;
          width: 490px;
          height: 32px;
          padding: 0px 4px;
          border: 2px solid #ea4a36;
          float: left;
          &:focus {
            outline: none;
          }
        }
        button {
          height: 32px;
          width: 68px;
          background-color: #ea4a36;
          border: none;
          color: #fff;
          float: left;
          cursor: pointer;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
</style>
